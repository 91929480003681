'use strict';

var R = require(29);

var fixes = require(13);
var inifiniteScroll = require(16);
var productTile = require(21);
var headerMessage = require(15);

R(R.EVENTS.LOAD, [R.SECTIONS.PRODUCT_LIST, R.SECTIONS.SEARCH], function() {
    productTile.update();
    fixes.productListFixes();
    inifiniteScroll.init();
    headerMessage.showMessage();
    R.fire(R.EVENTS.POST_LOAD);
});
